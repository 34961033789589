.content{
  .home{
    .container {
      display: flex;
      width: 100%;
      flex-direction: row;
      gap: 20px;
      a{
        text-decoration: none;
        color:black;
      }
      a:hover{
        color:grey;
      }
      .fifty{
        flex: 2;
        &.main {
          width: 33%;
          flex: 1.5;
        }
        &.updates {
          display: flex;
          flex-flow: row wrap;
          gap: 10px;
          .entery {
            margin-bottom: 10px;
            display: flex;
            margin: 0px 0 10px 20px;
            width: 46%;
            // padding: 10px 0;
            border-bottom: 1px solid lightgray;
            text-align: justify;
          }
        }
      }
    }
    @media screen and (max-width: 960px) {
    .container {
      flex-direction:column;
      gap: 0px;
        .fifty{
          &.main{
            margin: 5% 5% 0 5%;
            width: 90%;
          }
          &.updates {
            .entery {
              width: 90%;
              padding-bottom: 20px;
            }
          }
        }
      }
    }
  }
}