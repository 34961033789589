
.contact {
    ul{
    list-style-type: none;
    max-width: 200px;
    li {
            border-bottom: 1px solid lightgrey;
            margin-bottom: 20px;
            font-size: 13px;
            padding: 10px;
            a, link {
                color: grey;
                text-decoration: none;
            }
        }
    }
    @media screen and (max-width: 960px) {
            margin-top: 50px;
        }
}
.bio {
    display: flex;
    gap: 40px;
    p {
        margin-bottom: 10px;
    }
    .main, .image {
        text-align: justify;
    }
    .image {
        width: 240%;
        margin-top: 50px;
        text-align: right;
        span {
            font-size: .75em;
        }
    }
    @media screen and (max-width: 960px) {
            display: block;
            margin: 0 10px ;
            .image {
                margin-top: 10px;
                width:auto;
            }
    }
    
}



.sectionTitle {
    display: none;
}