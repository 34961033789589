img {
  max-width: 100%;
  display: block;
}
.content{
  padding: 6px 20px;

  .sectionTitle {
    font-weight: 100;
  }
.container {

  .galleryWrap {
    // column-count: 4;
    // column-gap: 10px;
    // position: relative;
    // justify-content: center;
    display: flex;
    flex-wrap: wrap;
    counter-reset: paginate-counter 0;
    .single {
      // margin-bottom: 10px;
      display: inline;
      line-height: 150px;
      // background: #c4c4c4;
      box-shadow: 0 0 0 .25px #989898;
      //color: white;
      margin: 0 1rem 1rem 0;
      text-align: center;
      font-family: system-ui;
      font-weight: 900;
      font-size: 2rem;
      flex: 1 0 auto;
      overflow:hidden;
    }
    @for $i from 1 through 1000 { 
      // div:nth-child(#{$i}) {
      //   $h: (random(200) + 70) + px;
      //   width: $h;
        img {
          min-width:100px;
          max-width:none;
          height:200px;
          //margin:0 -100%;
          position: relative;
          // left: -20%;
          scale: 3;
          opacity: 0;
          &.image-loaded{
            opacity: 1;
            -webkit-transition: all 2s ease; 
            -moz-transition: all 2s ease; 
            -ms-transition: all 2s ease; 
            -o-transition: all 2s ease; 
          }
        }
      // }
    }
    .single:hover{
      img{
      transition: transform .2s; /* Animation */
      transform: scale(1.03);
      }
    }
    // .single:not(.previous):not(.next):after {
    //   counter-increment: paginate-counter;
    //   content: counter(paginate-counter);
    // }
  }
  .sliderWrap {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    .fullScreenImage {
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: auto;
      img {
        max-width: 100%;
        max-height: 80vh;
        margin-top: -40px;
        pointer-events: none;
        user-select: none;
      }
      .title {
        text-align: left;
      }
      .btn {
        position: fixed;
        cursor: pointer;
        opacity: .6;
        z-index: 100;
        font-weight: 900;
        padding:5px 10px 5px 10px;
        font-size: 2em;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.5);
        color: #212529;
        border: 0;
        &:hover{
          opacity: 1;
        }
      }
      .btnClose{
        top: 62px;
        right: 0px;
      }
      .btnBack{
        top:50%;
        transform: translate(-50%);
        left: 19px;
      }
      .btnNext{
        top:50%;
        transform: translate(-50%);
        right: -18px;
      }
    }
  }
  }
  @media screen and (max-width: 960px) {
    padding: 0;
    .container {
      padding: 34px 0;
      .sliderWrap .fullScreenImage .btnClose{
        top: 77px!important;
      }
      .galleryWrap {
        // column-count: 3;
        padding-left: 8px;
        .single {
          // margin-bottom: 10px;
          display: inline;
          line-height: 50px;
          //background: #EC985A;
          //color: white;
          margin: 0 .5rem .5rem 0;
          text-align: center;
          font-family: system-ui;
          font-weight: 900;
          font-size: 2rem;
          flex: 1 0 auto;
          overflow:hidden;
        }
        @for $i from 1 through 1000 { 
          // div:nth-child(#{$i}) {
          //   $h: (random(100) + 25) + px;
          //   width: $h;
            img {
              min-width:100px;
              max-width:none;
              height:100px;
              position: relative;
              scale:2.7;
            }
          // }
        }
      }
    }
    .sectionTitle {
      position: absolute;
      top: 11px;
      left: 212px;
      display: block;
    }
    h3.sectionTitle{
      top: 17px;
    }
   
  }
}